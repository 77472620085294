import { useState, useEffect } from "react";

export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

export const MediaQuery = {
  LargeMobile: "(min-width: 35em)",
  Tablet: "(min-width: 48em)",
  Desktop: "(min-width: 60em)",
  Widescreen: "(min-width: 75em)",
};
