import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useMediaQuery, MediaQuery } from "../../hooks/useMediaQuery";

import * as styles from "./Header.module.scss";

const Header = () => {
  const [title, setTitle] = useState("Graphic & Media Design showcase 2023");
  const tablet = useMediaQuery(MediaQuery.Tablet);
  const desktop = useMediaQuery(MediaQuery.Desktop);

  useEffect(() => {
    if (!tablet && !desktop) {
      setTitle("GMD 2023");
    } else if (tablet && !desktop) {
      setTitle("Graphic & Media Design showcase 2023");
    } else if (desktop) {
      setTitle("Graphic & Media Design showcase 2023");
    }
  }, [tablet, desktop]);

  return (
    <header className={styles.header} aria-label="Header">
      <nav aria-labelledby="mainmenulabel">
        <h2 id="mainmenulabel" className="visually-hidden">
          Main Menu
        </h2>
        <ul>
          <li className={styles.first}>
            <Link to="/">{title}</Link>
          </li>
          {/* <li className={styles.rightLink}>
            <Link
              to={`/`}
              state={{ targetFragment: "show" }}
              activeClassName="transferActive"
            >
              transfer
            </Link>
          </li> */}
          <li className={styles.rightLink}>
            <Link
              to={`/`}
              state={{ targetFragment: "show" }}
              activeClassName="active"
            >
              student work
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export { Header };
