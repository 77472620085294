import React, { createContext, useState, useEffect } from "react";

import { colorModes, textModes } from "../../utils/common";
import { currentYear } from "../../utils/common";

const defaultValues = {
  studentYear: currentYear,
  colorMode: colorModes[0],
  setColorMode: () => false,
  textOnly: false,
};

export const GMDContext = createContext(defaultValues);

export const GMDProvider = ({ children }) => {
  const [studentYear, setStudentYear] = useState(200);
  const [colorMode, setColorMode] = useState(colorModes[0]);
  const [textOnly, setTextOnly] = useState(textModes[1]);

  useEffect(() => {
    colorModes.forEach((cm) => {
      document.documentElement.classList.toggle(`${cm}-mode`, cm === colorMode);
    });
  }, [colorMode]);

  useEffect(() => {
    textModes.forEach((tm) => {
      document.documentElement.classList.toggle(`image-${tm}`, tm === textOnly);
    });
  }, [textOnly]);

  return (
    <GMDContext.Provider
      value={{
        studentYear,
        setStudentYear,
        colorMode,
        setColorMode,
        textOnly,
        setTextOnly,
      }}
    >
      {children}
    </GMDContext.Provider>
  );
};
