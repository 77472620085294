import React, { useContext } from "react";
import { Link } from "gatsby";
import * as styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <h2 id="footermenulabel" className="visually-hidden">
        Credits
      </h2>
      <nav aria-labelledby="footermenulabel">
        <div className={styles.column}>
          <h3>BA (Hons) Graphic & Media Design</h3>
          <div>
            <br />
            London College of Communication
            <br />
            University of the Arts London
            <br />
          </div>
          <h3>Course Leader</h3>
          <ul>
            <li>Ian Carr</li>
          </ul>
          <h3>Lecturers</h3>
          <ul>
            <li>
              Maria Da Gandra
              <br />
              (Y3 Coordinator)
            </li>
            <li>
              Alexander Cooper
              <br />
              (Y2 Coordinator)
            </li>
            <li>
              Craig Burston
              <br />
              (Y1 Coordinator)
            </li>
            <li>Alistair McClymont</li>
            <li>Deborah Tchoudjinoff</li>
            <li>Marit Muenzberg</li>
            <li>Monica Biagioli</li>
            <li>Owen Wells</li>
            <li>Ravin Raori</li>
            <li>Siân Cook</li>
            <li>Tim Hutchinson</li>
          </ul>
        </div>

        <div className={styles.column}>
          <h3>Associate Lecturers</h3>
          <ul>
            <li>Alessia Mazzarella</li>
            <li>Deshna Mehta</li>
            <li>Emma Charlston</li>
            <li>Giorgio del Buono</li>
            <li>Irene Martin</li>
            <li>Jazmin Morris</li>
            <li>Jesse Thompson</li>
            <li>Joana Chicau</li>
            <li>Jonny Thaw</li>
            <li>Kasia Piechota</li>
            <li>Kelly Hall</li>
            <li>Linda Byrne</li>
            <li>Michal Polak</li>
            <li>Oswin Tickler</li>
            <li>Pedro Pina</li>
            <li>Peter Chadwick</li>
            <li>Ronnie Deelen</li>
            <li>Sheran Forbes</li>
            <li>Shernette Daly</li>
            <li>Sindi Breshani</li>
            <li>Svenja Frahm</li>
            <li>Tai Cossich</li>
            <li>Tommy Brentnall</li>
            <li>Tiziana Alocci</li>
          </ul>
        </div>

        <div className={styles.column}>
          <h3>Special thanks</h3>
          <ul>
            <li>Dr Helga Schmid</li>
            <li>Amanda Jenkins</li>
            <li>Dr Nicky Ryan</li>
            <li>Cathy Reilly</li>
            <li>Michael Hodges</li>
          </ul>
          <h3>Course Reps</h3>
          <ul>
            <li>Mike Williams</li>
            <li>Emma Vukman</li>
            <li>Ben Heathorn</li>
            <li>Martyna Bogdal</li>
            <li>Fabian Drake</li>
            <li>Jiwoo Kim</li>
          </ul>
          <h3>Production team</h3>
          <ul>
            <li>Caitriona McAllister</li>
            <li>Catarina Archer</li>
            <li>Chihiro Shigemitsu</li>
            <li>Mary Adekoya</li>
            <li>Milena Georgieva</li>
            <li>Pearl Geraldi</li>
          </ul>
          <h3>Alumni Residency</h3>
          <ul>
            <li>Luke Augur</li>
            <li>Jacob Deakin</li>
          </ul>
          <h3>Technical team</h3>
          <ul>
            <li>Scott House</li>
            <li>Tony Yard</li>
            <li>Daniel Fletcher</li>
            <li>Jessica Copsey</li>
            <li>Richard Roberts</li>
            <li>Andrew Long</li>
            <li>Rahel Zoller</li>
            <li>Claire Grant</li>
            <li>Jason Henry</li>
            <li>Tilly De Verteuil</li>
            <li>Gregor Garber</li>
          </ul>
        </div>

        <div className={styles.column}>
          <h3>Critical forum</h3>
          <ul>
            <li>Corpo</li>
            <li>A Practice for Everyday Life Svenja Frahm</li>
            <li>Studio Hato</li>
            <li>John Maeda</li>
            <li>Sophie Mei</li>
            <li>Kesselskramer</li>
            <li>Superdot</li>
            <li>Sarah Harry Issacs</li>
            <li>Samar Maarkaroun</li>
            <li>Kathy Chan</li>
            <li>Jake Richardson Alumni</li>
            <li>Karoline Winzer Alumni</li>
            <li>Lilla Cseh Alumni</li>
            <li>Aaron Honda Alumni</li>
            <li>Mohammed Samad Alumni</li>
          </ul>
        </div>
      </nav>
    </footer>
  );
};

export { Footer };
